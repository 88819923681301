<template>
  <div class="btn__loading">
    <IconLogo />
  </div>
</template>

<script>
import IconLogo from '@/assets/icons/logo.svg';

export default {
  name: 'BtnLoading',
  components: {
    IconLogo,
  },
}
</script>

<style lang="scss" scoped>
.btn__loading {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 40%;
    position: relative;
    display: block;
    z-index: 2;

    path {
      opacity: 0.3;
      stroke: #fff;
      stroke-dasharray: 2000;
      stroke-dashoffset: 2794;
      animation: DrawLine 2s linear infinite;
    }
  }
}
</style>
